<template>
  <Wrapper
    size="s"
  >
    <component
      :is="tag"
      :class="['title', `typo--${size}`, theme]"
      v-html="value"
    />
  </Wrapper>
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let data = {
      value: '',
      tag: 'h2',
      size: 'l',
      theme: '',
    };
    data = { ...data, ...this.data };
    return data;
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: $secondary-color;

  &.typo--m {
    @extend %typo--cta;
  }
}

.sans {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
</style>
