var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",class:[
    _vm.layout.section_classes !== '' ? _vm.layout.section_classes.split(' ') : null,
    _vm.layout.section_layout === 'custom' ? _vm.layout.section_layout_custom : null,
    _vm.layout.section_text === 'inverse' ? 'section--text-inverse' : null,
  ],style:({
    backgroundColor: _vm.layout.section_background !== '' ? _vm.layout.section_background : null,
  }),attrs:{"id":_vm.layout.section_id ? _vm.layout.section_id : null}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }