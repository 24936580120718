<template>
  <div class="carousel">
    <slot />
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

require('flickity-imagesloaded');

export default {
  name: 'Carousel',
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        groupCells: window.innerWidth > 1000,
        prevNextButtons: window.innerWidth > 1000,
        contain: window.innerWidth > 1000,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Flickity(this.$el, Object.assign(this.settings, this.extraSettings));
    });
  },
  beforeUnmount() {
    this.carousel.destroy();
  },
};
</script>

<style scoped lang="scss">
.item {
  width: 80%;
  min-height: 100%;
  display: flex;
  margin: 0 10px;

  @include mq(m) {
    margin-right: 40px;
    width: calc(33.333333% - 33.333333px);
  }
}
</style>

<style lang="scss">
.flickity-viewport {
  @include mq($until: m) {
    overflow: visible;
  }
}

.flickity-page-dots {
  bottom: -30px;
}

.flickity-button-icon {
  fill: $green;
}

.flickity-page-dots .dot {
  background: $green;
}

.flickity-prev-next-button.previous {
  left: -15px;
  @include mq(m) {
    left: -60px;
  }
}

.flickity-prev-next-button.next {
  right: -15px;
  @include mq(m) {
    right: -60px;
  }
}

.flickity-button:disabled {
  display: none;
}

</style>
