<template>
  <Spacer
    class="snackbar"
    :x="{ default: 's', m: 'l' }"
    y="xs"
  >
    <Flex
      align="header"
      class="flex-header"
    >
      <Cta
        :data="{ title: snackbar.text, size: 'snackbar', fn: goToFn }"
        class="cta-left"
      />
      <Cta
        :data="{ title: snackbar.link2, url: snackbar.link, toggle: 'cta', target: '_blank' }"
        class="cta-right"
      >
        <template
          v-slot:cta
        >
          <inline-svg
            :src="require(`@/assets/svg/icons/cta.svg`)"
            class="icon"
          />
        </template>
      </Cta>
    </Flex>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import goTo from '@/mixins/goTo';
import Cta from '@/components/typo/cta';

export default {
  name: 'Snackbar',
  components: {
    Cta,
  },
  mixins: [goTo],
  computed: {
    ...mapGetters(['snackbar']),
  },
  methods: {
    goToFn() {
      const e = {
        target: {
          hash: this.snackbar.link2,
        },
      };
      this.to(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background: $secondary-color;
  color: $white;
  min-height: 42px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  & > * {
    width: 100%;
  }

  .icon {
    height: 22px;
    margin-left: 6px;

    @include mq(m) {
      height: 27px;
      margin-left: 12px;
    }
  }

  .cta-left {
    text-align: left;
    flex: 2 1 0;
  }

  .cta-right {
    text-align: right;
    flex: 1 1 0;
  }

  @include mq($until: m) {
    ::v-deep .typo--cta {
      font-size: 13px;
    }
  }

  @include mq(m) {
    height: 42px;
    align-items: center;
  }
}
</style>
