<template>
  <Wrapper
    size="s"
    :class="['wysiwyg', data.size ? `typo--${data.size}` : null]"
  >
    <div
      v-html="data.value"
    />
  </Wrapper>
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/src/03_typography';

.wysiwyg {
  ul, ol {
    display: block;
    margin: 1em 0;
    padding-left: $unit*2;
  }

  ul {
    list-style-type: disc;
  }

  em, i {
    color: $primary-color;
    font-style: normal;
  }

  p + p {
    margin-top: 1em;
  }

  a {
    border-bottom: 1px solid currentColor;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
</style>
