import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  methods: {
    to(e) {
      if (e.preventDefault) e.preventDefault();
      const { hash } = e.target;
      const { top } = document.querySelector(hash).getBoundingClientRect();

      gsap.to(window, {
        scrollTo: {
          y: document.querySelector(hash),
          offsetY: top >= 0 ? 42 : 111,
        },
        duration: 1,
        ease: 'expo.out',
      });
    },
  },
};
