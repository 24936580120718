<template>
  <main>
    <Figure
      v-if="post.gds_featured_image"
      :data="post.gds_featured_image"
    />
    <Title :data="{ value: post.title.rendered }" />
    {{ post }}
  </main>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

import data from '@/mixins/data';
import lazyload from '@/mixins/lazyload';

export default {
  name: 'Single',
  components: {
    Figure,
    Title,
  },
  mixins: [data, lazyload],
};
</script>
