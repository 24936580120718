<template>
  <header :class="[menuStatus ? 'menu-opened' : false, {hide: showModal && width < 750}]">
    <Spacer
      :x="{ default: 'm', m: 'l' }"
      y="s"
    >
      <Flex align="header">
        <div class="logo">
          <img
            :src="require('@/assets/img/logo.svg')"
            alt="SiRicarica"
          >
        </div>
        <Menu
          :status="menuStatus"
          :set-status="closeMenu"
        />
        <div class="contact u-hidden--until-m">
          <a
            href="#footer"
            @click.prevent="to"
          >Contatti</a>
        </div>

        <div
          class="burger u-hidden--from-m"
          @click="toggleMenu"
        >
          <div class="line" />
          <div class="line" />
          <div class="line" />
        </div>
      </Flex>
    </Spacer>
  </header>
</template>

<script>
import goTo from '@/mixins/goTo';
import Menu from '@/components/ui/menu';
import { mapGetters } from 'vuex';
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

export default {
  name: 'Header',
  components: {
    Menu,
  },
  mixins: [goTo],
  data() {
    return {
      menuStatus: false,
      lastScroll: -1,
      dir: false,
      width,
    };
  },
  mounted() {
    this.$aion.add(this.checkHeader, 'checkHeader');
  },
  computed: {
    ...mapGetters(['places', 'showModal']),
  },
  beforeDestroy() {
    this.$aion.remove('checkHeader');
  },
  methods: {
    toggleMenu() {
      this.menuStatus = !this.menuStatus;

      document.body.classList.toggle('menu-opened');
    },
    closeMenu() {
      this.menuStatus = false;

      document.body.classList.remove('menu-opened');
    },
    checkHeader() {
      if (window.pageYOffset <= 111) {
        this.lastScroll = -1;
        if (this.$el.classList.contains('header-scrolling')) this.$el.classList.remove('header-scrolling');
        return;
      }

      this.dir = (window.pageYOffset >= this.lastScroll);

      if (this.dir) {
        this.lastScroll = window.pageYOffset;
        if (!this.$el.classList.contains('header-scrolling')) this.$el.classList.add('header-scrolling');
      } else {
        this.lastScroll = window.pageYOffset + 1;
        if (this.$el.classList.contains('header-scrolling')) this.$el.classList.remove('header-scrolling');
      }
    },
  },

};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 10;
  background: $white;
  filter: drop-shadow(0 1px 12px rgba(0,0,0, 0.7));
  transition: transform 0.5s $ease-custom;

  &.hide{
    display: none;
  }

  &.header-scrolling {
    transform: translate3d(0, -100%, 0);
  }

  @extend %typo--menu;

  a:hover {
    color: $secondary-color;
  }

  .logo {
    width: 127px;
    line-height: 0;

    transform: translate3d(-9px, 0, 0);
  }
  .contact {
    color: $primary-color;
  }

  .burger {
    position: relative;
    width: 16px;
    height: 14px;
    cursor: pointer;

    .line {
      position: absolute;
      left: 0;
      top: 6px;
      width: 100%;
      height: 2px;
      background: $secondary-color;
      transition: transform 0.3s $ease-custom;

      &:nth-child(1) {
        transform: translateY(-6px);
      }
      &:nth-child(3) {
        transform: translateY(6px);
      }
    }
  }

  &.menu-opened {
    .burger {
      .line {
        &:nth-child(1) {
          transform: translateY(0px) rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(0px) rotate(45deg);
        }
      }
    }
  }
}
.snackbar + header {
  top: 42px;
  @include mq(m) {
    top: 42px;
  }
}
</style>
