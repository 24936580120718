<template>
  <Spacer>
    <div class="spacer--padding--t--xxl">
      <div
        :class="['iframe-div', {'youtube' : data.embed.includes('youtube')}]"
        v-html="data.embed"
      />
    </div>
  </Spacer>
</template>

<script>
export default {
  name: 'Embed',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style scoped>
.iframe-div {
  max-width: 1116px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  height: 70vh;

  @media only screen and (min-width: 700px) and (max-width: 1299px) {
    padding-bottom: 65%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1699px) {
    padding-bottom: 50%;
  }
  @media only screen and (min-width: 1700px) {
    padding-bottom: 40%;
  }

  &.youtube {
    height: calc(100vw / 16 * 9);
    max-height: calc(1116px / 16 * 9);
    padding-bottom: 0;

    ::v-deep iframe{
      width: 100%;
      height: 100%;
    }
  }
}
.iframe-div >>> * {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-button {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
</style>
