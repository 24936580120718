<template>
  <button @click="fn">
    <inline-svg
      :src="require(`@/assets/svg/icons/${name}.svg`)"
      :style="`stroke: ${strokeColor}`"
    />
  </button>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    fn: {
      type: Function,
      default: () => {},
    },
    strokeColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0)',
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 20px;
  height: 20px;
  color: inherit;

  pointer-events: auto;
  cursor: pointer;

  svg {height: 100%;}
}
</style>
