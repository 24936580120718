import {
  fetchItems,
  fetchSingle,
} from '@/api';

export default {
  async getSingleBySlug({ getters, commit }, {
    type, slug, showLoading = false,
  }) {
    let response = null;
    const responseFromState = getters.singleBySlug({ type, slug });
    if (!responseFromState) {
      if (showLoading) {
        commit('SET_LOADING', true);
      }
      await fetchSingle({ type, params: { slug } }).then(({ data: [item] }) => {
        commit('ADD_ITEM', { type, item });
        if (showLoading) {
          commit('SET_LOADING', false);
        }
        response = item;
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getItems({ getters, commit }, {
    type, params, showLoading = false,
  }) {
    let response = null;
    const responseFromState = getters.request({ type, params });
    if (!responseFromState) {
      if (showLoading) {
        commit('SET_LOADING', true);
      }
      await fetchItems({ type, params })
        .then(({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
          items.forEach((item) => commit('ADD_ITEM', { type, item }));
          commit('ADD_REQUEST', {
            type,
            request: {
              type,
              params,
              total: parseInt(total, 10),
              totalPages: parseInt(totalPages, 10),
              data: items.map((i) => i.slug),
            },
          });
          response = items;
          if (showLoading) {
            commit('SET_LOADING', false);
          }
        });
    } else {
      response = responseFromState.data.map((slug) => getters.singleBySlug({ type: responseFromState.type, slug }));
    }

    return response;
  },
  updateDocTitle({ state, commit }, { parts = [], sep = ' – ' }) {
    commit('SET_DOC_TITLE', parts.join(sep));
    document.title = state.site.docTitle;
  },
};
