<template>
  <nav :class="[status ? 'nav-opened' : false]">
    <Flex
      align="h-around"
      :dir="{ default: 'column', m: 'row'}"
      class="flex-nowrap"
    >
      <div
        v-for="item in navigation"
        :key="item.id"
        :class="[item.css, 'item']"
      >
        <Spacer
          x="s"
          :y="{ default: 'xs', m: 0 }"
        >
          <a
            :href="item.url"
            @click="click"
          >
            {{ item.content }}
          </a>
        </Spacer>
      </div>
      <Spacer
        :top="{ default: 'l', m: 0 }"
        class="item contact u-hidden--from-m"
      >
        <a
          href="#footer"
          @click="click"
        >Contatti</a>
      </Spacer>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import goTo from '@/mixins/goTo';

export default {
  name: 'Menu',
  mixins: [goTo],
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    setStatus: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  methods: {
    click(e) {
      e.preventDefault();
      this.setStatus();
      this.to(e);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  top: 69px;
  left: 0;
  width: 100%;
  height: auto;
  background: $white;
  pointer-events: none;
  clip-path: inset(0% 0 100% 0);
  transition: clip-path 0.6s $ease-custom;

  @include mq($until: m) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 45px;

  }

  @include mq(m) {
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    position: absolute;
    background: transparent;
    pointer-events: auto;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    clip-path: none;

    .flex-nowrap {
      flex-wrap: nowrap;
    }
  }

  &.nav-opened {
    pointer-events: auto;
    clip-path: inset(0% 0 0 0);
  }

  .item {
    @include mq($until: m) {
      text-align: center;
      width: 100%;
    }

    a:hover {
      color: $secondary-color;
    }
  }

  .contact {
    color: $primary-color;
  }
}
</style>
