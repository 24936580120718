var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Wrapper',{staticClass:"content",attrs:{"size":"l"}},[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
      value: _vm.data.label,
      tag: _vm.data.tag_label,
      size: _vm.data.size_label
    }}}):_vm._e(),(_vm.data.title !== '')?_c('Spacer',{staticClass:"spacer--title",attrs:{"bottom":_vm.data.rich_text !== '' && !_vm.repeater ? { default: 's', m: 'l' } : false}},[_c('Title',{attrs:{"data":{
        value: _vm.data.title,
        tag: _vm.data.tag_title,
        size: _vm.data.size_title
      }}})],1):_vm._e(),(_vm.data.rich_text !== '')?_c('Spacer',{staticClass:"spacer--wysiwyg",attrs:{"top":_vm.repeater ? { default: 'xs', m: 'm' } : { default: 's', m: 'xxl' },"bottom":_vm.repeater ? false : { default: 'xxl', m: 'xxxl' }}},[_c('RichText',{attrs:{"data":{ value: _vm.data.rich_text, size: _vm.repeater ? 's' : null }}})],1):_vm._e(),(_vm.data.cta && _vm.data.cta !== '')?_c('Spacer',{attrs:{"top":{ default: 's', m: 'xl' }}},[_c('Cta',{attrs:{"data":{..._vm.data.cta, style: _vm.data.cta_style}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }