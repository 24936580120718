<template>
  <Wrapper
    size="l"
    class="content"
  >
    <Label
      v-if="data.label && data.label !== ''"
      :data="{
        value: data.label,
        tag: data.tag_label,
        size: data.size_label
      }"
    />
    <Spacer
      v-if="data.title !== ''"
      class="spacer--title"
      :bottom="data.rich_text !== '' && !repeater ? { default: 's', m: 'l' } : false"
    >
      <Title
        :data="{
          value: data.title,
          tag: data.tag_title,
          size: data.size_title
        }"
      />
    </Spacer>
    <Spacer
      v-if="data.rich_text !== ''"
      :top="repeater ? { default: 'xs', m: 'm' } : { default: 's', m: 'xxl' }"
      :bottom="repeater ? false : { default: 'xxl', m: 'xxxl' }"
      class="spacer--wysiwyg"
    >
      <RichText
        :data="{ value: data.rich_text, size: repeater ? 's' : null }"
      />
    </Spacer>
    <Spacer
      v-if="data.cta && data.cta !== ''"
      :top="{ default: 's', m: 'xl' }"
    >
      <Cta
        :data="{...data.cta, style: data.cta_style}"
      />
    </Spacer>
  </Wrapper>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    repeater: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  ::v-deep a {
    border-bottom: 1px solid currentColor;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
</style>
