<template>
  <div id="app">
    <Snackbar v-if="snackbar.text || snackbar.link2" />
    <AppHeader />
    <router-view />
    <AppFooter />

    <Places />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import { mapGetters } from 'vuex';
import { isTouchDevice, is, isIOS } from '@/assets/js/utils';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import Snackbar from '@/components/custom/snackbar';
import Places from '@/components/custom/places';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Snackbar,
    Places,
  },
  data() {
    return {
      prevHeight: null,
    };
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Look at the state ;)
    // console.log(this.$store.state);

    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
      }, 400),
    );

    this.$bus.$on('windowResized', this.resize);
    this.resize();
  },
  computed: {
    ...mapGetters(['snackbar']),
  },
  methods: {
    resize() {
      if (
        !isIOS()
        || !this.prevHeight
        || Math.abs(this.prevHeight - window.innerHeight) > 150
        || !is('m')
      ) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }

      this.prevHeight = window.innerHeight;

      const breakpoints = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--breakpoints')
        .replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE', is('m'));
    },
    enter(el, done) {
      done();
    },
    leave(el, done) {
      done();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
