<template>
  <component
    :is="data.tag"
    :class="['label', `typo--${data.size}`, data.theme]"
    v-html="data.value"
  />
</template>

<script>
export default {
  name: 'Label',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
        tag: 'h3',
        size: 'm',
        theme: 'sdad',
      }),
    },
  },
};
</script>

<style>

.sans {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

</style>
