var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cta"},[(_vm.data.fn)?[_c('button',{on:{"click":_vm.data.fn}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 'cta',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.data.target === '_blank')?[_c('a',{class:_vm.data.style,attrs:{"href":_vm.data.url,"target":_vm.data.target}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 'cta',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{class:_vm.data.style,attrs:{"to":_vm.data.url}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 'cta',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }