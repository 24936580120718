<template>
  <main>
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="`section--${parentIndex}`"
    >
      <component
        :is="components.get(block.acf_fc_layout)"
        v-for="(block, index) in section.blocks"
        :key="index"
        :class="`block-${block.acf_fc_layout}`"
        :data="block"
      />
    </Section>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import goTo from '@/mixins/goTo';
import data from '@/mixins/data';

import Section from '@/components/section';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Placeholder from '@/components/blocks/placeholder';
import Embed from '@/components/blocks/embed';

export default {
  name: 'Page',
  components: {
    Section,
    Content,
    Media,
    Repeater,
    List,
    Archive,
    Placeholder,
    Embed,
  },
  mixins: [data, goTo],
  data() {
    return {
      components,
    };
  },
  mounted() {
    const link = this.$el.querySelector('.hero a');
    link.addEventListener('click', (e) => {
      e.preventDefault();
      this.to(e);
    });
  },
  methods: {
    layout(section) {
      const {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
      } = section;
      return {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/src/grid/_grid";
@import "@/assets/scss/src/grid/_wrapper";

main {
  position: relative;
  padding-top: 40px;
  overflow: hidden;
}
section.hero {
  position: relative;

  .block-media {
    height: calc((var(--vh) * 100) - 82px);

    .picture {
      height: calc((var(--vh) * 100) - 82px);

      picture {
        height: calc((var(--vh) * 100) - 82px);
        padding-bottom: 0 !important;
      }
    }
  }
  .block-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    color: $white;
    text-align: center;

    .wrapper--s {
      margin-left: auto;
      max-width: none;
    }

    .typo--xxl {
      color: $white;
      font-weight: 400;

      @include mq($until: s) {
        font-size: 34px;
      }
      @include mq($until: m) {
        font-size: 46px;
      }
    }

    .spacer--title {
      position: absolute;
      left: 22.5px;
      width: calc(100% - 45px);
      bottom: 50px;
      text-align: left;

      @include mq(m) {
        left: 0;
        text-align: center;
        width: 100%;
        bottom: 130px;
      }
    }

    .cta {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      display: none;

      a {
        border: 0;
      }

      @include mq(m) {
        display: block;
      }

      .label {
        position: relative;

        @extend %typo--m;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translate3d(-50%, 10px, 0);
          width: 23px;
          height: 23px;
          background-image: url("~@/assets/svg/icons/scroll.svg");
          background-size: 100% 100%;
        }
      }
    }
  }
}

section:not(.hero) {
  padding-top: 45px;
  padding-bottom: 55px;
  padding-left: 22.5px;
  padding-right: 22.5px;

  @include mq(m) {
    padding-top: 90px;
    padding-bottom: 140px;
  }
}

.block-content {
  .wrapper--s {
    margin-left: 0;
  }
}

section.block-cta {
  @extend .grid;
  @extend .grid-12;
  @extend .grid-m-6;
  @extend .wrapper;
  @extend .wrapper--xxl;
  grid-gap: 0;

  @include mq(m) {
    padding-bottom: 70px;
  }

  .block-media {
    picture,
    figure {
      height: 100%;
      padding-bottom: 0;
    }
    picture {
      padding-bottom: 100% !important;
    }
  }

  .block-content {
    padding: 30px;
    aspect-ratio: 1/1;

    @include mq(m) {
      padding: 60px;
    }

    .wysiwyg {
      @extend %typo--s;
    }

    .spacer--wysiwyg {
      @include mq(m) {
        padding-top: 0px;
        padding-bottom: 30px;
      }
    }
    .cta {
      @extend .cta-boxed;
    }
  }

  &.background__green {
    .block-content {
      background: $green;
    }
  }
}

section.project {
  .block-content {
    @include mq(m) {
      text-align: center;
    }

    .wrapper--s {
      margin-left: auto;
    }
  }
}

section.values {
  .block-content {
    text-align: center;
    padding-bottom: 30px;

    @include mq(m) {
      padding-bottom: 90px;
    }

    .wrapper--s {
      margin-left: auto;
    }
  }

  .block-repeater {
    @include mq($until: m) {
      .grid {
        row-gap: 30px;
      }
    }

    img {
      width: 80px;

      @include mq(m) {
        width: 160px;
      }
    }
  }
}

section.t-center {
  .wrapper--s {
    margin-left: auto;
  }
}

section.colonnine {
  .spacer--wysiwyg {
    padding-bottom: 0;
  }
}

section.team {
  .block-content {
    text-align: center;

    padding-bottom: 30px;
    @include mq(m) {
      padding-bottom: 90px;
    }

    .wrapper--s {
      margin-left: auto;
    }
  }

  .block-repeater {
    text-align: center;

    @include mq($until: m) {
      .grid {
        row-gap: 30px;
      }
    }

    .item {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;

      & > * {
        flex: 0 0 100%;
      }

      & > *:first-child {
        order: 2;

        .title {
          color: $black;
          font-family: $inter;
        }

        .spacer--wysiwyg {
          padding-top: 5px;
        }

        .wysiwyg.typo--s {
          @include mq($until: m) {
            font-size: 12px;
          }
        }

        .wysiwyg {
          font-size: 14px;

          @include mq($until: m) {
            font-size: 14px;
          }
        }
      }

      & > *:last-child {
        line-height: 0;
        font-size: 0;
        order: 1;
        padding-bottom: 15px;

        @include mq(m) {
          padding-bottom: 22.5px;
        }

        figure {
          border-radius: 50%;
          max-width: 120px;
          margin: 0 auto;

          @include mq(m) {
            max-width: 182px;
          }
        }
      }
    }
  }
}

section.partner {
  @include mq($until: m) {
    .title.typo--m {
      font-size: 18px;
    }
  }
}

section.manifesto {
  .wysiwyg {
    max-width: 1149px;
    @extend %typo--xl;
  }

  .spacer--wysiwyg {
    padding-bottom: 0;
  }
}

section.press {
  .block-content {
    margin-bottom: 60px;

    @include mq($until: m) {
      margin-bottom: 30px;
    }
  }
}
</style>
