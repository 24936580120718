<template>
  <Wrapper size="l">
    <Grid
      :col="gridCol"
      :template="gridTemplate"
      :data-layout="data.style.type !== 'default' ? data.style.type : null"
      :class="[data.style.classes !== '' ? data.style.classes.split(' ') : null]"
    >
      <div
        v-for="(item, index) in data.items"
        :key="index"
        class="item"
      >
        <Content
          :data="content(item)"
          :repeater="true"
        />
        <Media :data="media(item)" />
      </div>
    </Grid>
  </Wrapper>
</template>

<script>
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';

export default {
  name: 'Repeater',
  components: {
    Content,
    Media,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      const elementsLength = this.data.items?.length || this.data.categories?.length || this.data.posts?.length;
      let defaultColsNumber = 3;
      if (elementsLength) {
        if (elementsLength < 5) {
          defaultColsNumber = 12 / elementsLength;
        } else {
          defaultColsNumber = 3;
        }
      }
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? defaultColsNumber.toString() : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          s: 6,
          m: defaultColsNumber.toString(),
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
  methods: {
    content(item) {
      const {
        label, tag_label, size_label, title, tag_title, size_title, rich_text, cta,
      } = item;
      return {
        label, tag_label, size_label, title, tag_title, size_title, rich_text, cta,
      };
    },
    media(item) {
      return item.media && item.media[item.media.type];
    },
  },
};
</script>

<style>

</style>
