<template>
  <div
    v-show="showModal"
    class="modal-background"
    @click.self="closeModal"
  >
    <div class="temp">
      <div class="sticky">
        <div class="search-wrapper">
          <div class="search-bar">
            <Icon
              name="search"
              class="search-icon"
              :stroke-color="iconColor"
            />
            <input
              type="text"
              placeholder="Cerca la colonnina SìRicarica"
              class="typo--m"
              @input="searchInput"
            >
          </div>
          <div
            class="pointer close desktop"
            @click="closeModal"
          >
            <Label
              :data="{
                value: 'Chiudi',
                size: 's',
                tag: 'p',
                theme: 'sans',
              }"
            />
          </div>
        </div>
      </div>

      <div v-if="filteredList.length > 0">
        <a
          v-for="(field, index) in filteredList"
          :key="index"
          :href="field[1].acf.address.url"
          target="_blank"
          class="modal-place"
        >
          <Title
            :data="{
              value: field[1].acf.sale_point,
              tag: 'h2',
              size: 's',
              theme: 'sans',
            }"
          />
          <Icon
            name="location"
            class="list-icon"
          />
          <Label
            :data="{
              value: `${field[1].acf.address.title}`,
              tag: 'h2',
              size: 'm',
            }"
          />
        </a>
      </div>
      <div
        v-else
        class="modal-place"
      >
        <Label
          :data="{
            value: 'Nessun risultato trovato',
            tag: 'h2',
            size: 'm',
          }"
        />
      </div>
    </div>

    <div
      class="pointer close mobile"
      @click="closeModal"
    >
      <Label
        :data="{
          value: 'Chiudi',
          size: 's',
          tag: 'p',
          theme: 'sans',
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useWindowSize } from '@vueuse/core';
import { ref } from 'vue';

import Icon from '@/components/ui/icon';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

const inputField = ref('');
const { width } = useWindowSize();

export default {
  name: 'Places',
  components: {
    Icon,
    Label,
    Title,
  },
  computed: {
    ...mapGetters(['places', 'showModal']),
    filteredList() {
      const asArray = Object.entries(this.places);
      return asArray.filter((field) => {
        const lowerSearch = inputField.value.toLowerCase();
        const lowerSalePoint = field[1].acf.sale_point.toLowerCase();
        const lowerBusinessName = field[1].acf.business_name.toLowerCase();
        const lowerAddress = field[1].acf.address.title.toLowerCase();

        return (
          lowerSalePoint.includes(lowerSearch)
          || lowerBusinessName.includes(lowerSearch)
          || lowerAddress.includes(lowerSearch)
        );
      });
    },
    iconColor() {
      return width.value < 750 ? '#d9d9d9' : '#ffffff';
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('SET_MODAL', false);
    },
    searchInput(e) {
      inputField.value = e.srcElement.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-background {
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.temp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 750px;
  width: 100%;
  height: 100dvh;
  overflow: scroll;
  background: white;
  z-index: 3;
  padding-bottom: calc(16px + 18px + 20px);

  @include mq(s) {
    padding-bottom: 0;
    left: calc(50% - 16px);
    margin: 16px;
    max-width: 750px;
    width: calc(100% - 32px);
    height: 440px;
  }
  @include mq(xl) {
    left: calc(50% - 16px);
    margin: 16px;
    max-width: 950px;
    width: calc(100% - 32px);
    height: 540px;
  }
}

.sans {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}
.modal-place {
  padding: 16px 16px 18px 16px;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5px;
  background-color: #f4f4f4;

  &:hover {
    background-color: #d9d9d9;
  }

  & :nth-child(n) {
    grid-column: span 2;
  }

  & :nth-child(1),
  & :nth-child(2) {
    grid-column: span 1;
    min-width: 0;
  }

  @include mq(m) {
    & :nth-child(n) {
      grid-column: span 2;
    }

    & :nth-child(2),
    & :nth-child(3) {
      grid-column: span 1;
      min-width: 0;
    }
  }

  .list-icon {
    height: 18px;
    align-self: center;

    @include mq(m) {
      height: 24px;
      margin-top: 2px;
      order: 2;
      justify-self: end;
      align-self: start;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.close.mobile {
  display: block;
  padding: 16px 16px 18px 16px;
  background-color: #007954;
  color: white;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  @include mq(s) {
    display: none;
  }
}
.search-wrapper {
  padding: 16px 16px 18px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: white;

  @include mq(s) {
    color: white;
    background-color: #007954;
  }

  .search-bar {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @include mq(s) {
      flex-direction: row;
    }
  }

  .close.desktop {
    display: none;
    @include mq(s) {
      display: block;
    }
  }

  input {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    border: 0px;
    color: black;
    &::placeholder {
      color: #d9d9d9;
    }

    @include mq(s) {
      color: white;
      &::placeholder {
        color: white;
      }
      margin-left: 15px;
    }
  }
}
</style>
