var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"modal-background",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"temp"},[_c('div',{staticClass:"sticky"},[_c('div',{staticClass:"search-wrapper"},[_c('div',{staticClass:"search-bar"},[_c('Icon',{staticClass:"search-icon",attrs:{"name":"search","stroke-color":_vm.iconColor}}),_c('input',{staticClass:"typo--m",attrs:{"type":"text","placeholder":"Cerca la colonnina SìRicarica"},on:{"input":_vm.searchInput}})],1),_c('div',{staticClass:"pointer close desktop",on:{"click":_vm.closeModal}},[_c('Label',{attrs:{"data":{
              value: 'Chiudi',
              size: 's',
              tag: 'p',
              theme: 'sans',
            }}})],1)])]),(_vm.filteredList.length > 0)?_c('div',_vm._l((_vm.filteredList),function(field,index){return _c('a',{key:index,staticClass:"modal-place",attrs:{"href":field[1].acf.address.url,"target":"_blank"}},[_c('Title',{attrs:{"data":{
            value: field[1].acf.sale_point,
            tag: 'h2',
            size: 's',
            theme: 'sans',
          }}}),_c('Icon',{staticClass:"list-icon",attrs:{"name":"location"}}),_c('Label',{attrs:{"data":{
            value: `${field[1].acf.address.title}`,
            tag: 'h2',
            size: 'm',
          }}})],1)}),0):_c('div',{staticClass:"modal-place"},[_c('Label',{attrs:{"data":{
          value: 'Nessun risultato trovato',
          tag: 'h2',
          size: 'm',
        }}})],1)]),_c('div',{staticClass:"pointer close mobile",on:{"click":_vm.closeModal}},[_c('Label',{attrs:{"data":{
        value: 'Chiudi',
        size: 's',
        tag: 'p',
        theme: 'sans',
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }