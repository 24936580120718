<template>
  <div
    class="cta"
  >
    <template v-if="data.fn">
      <button @click="data.fn">
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="data.target === '_blank'">
      <a
        :href="data.url"
        :target="data.target"
        :class="data.style"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="data.url"
        :class="data.style"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Cta',
  components: {
    Label,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};

</script>

<style lang="scss" scoped>
  .cta {
    display: block;
    line-height: 0;
  }
  a {
    display: inline-flex;
    align-items: center;

    &.button {
      background-color: #007954;
      color: white;
      justify-content: center;
      align-items: center;
      padding: 16px 40px 18px 40px;
      width: 100%;
      border-bottom: none !important;

    }

    * {
      pointer-events: none;
    }
  }
  .toggle {
    height: 100%;
  }
</style>
<!--
<template>
  <div class="cta">
    <template v-if="data.fn">
      <button @click="data.fn">
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div',
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="data.target === '_blank'">
      <a
        :href="data.url"
        :target="data.target"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div',
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link :to="data.url">
        <Label
          :data="{
            value: data.title,
            size: data.size || 'cta',
            tag: 'div',
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Cta',
  components: {
    Label,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.cta {
  display: block;
  line-height: 0;
  background-color: #007954;
  color: white;
}
a {
  display: inline-flex;
  align-items: center;
  border-bottom: none !important;
  padding: 16px 40px 18px 40px;
  width: 100%;

  * {
    pointer-events: none;
  }
}
.toggle {
  height: 100%;
}
</style> -->
