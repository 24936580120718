<template>
  <div
    ref="gallery"
    class="gallery"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      class="item"
    >
      <Figure
        :ref="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false,
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss">
.gallery {
  overflow: hidden;

  .flickity-viewport {
    overflow: visible;
  }
  .item {
    width: 33.33vw;
    margin-right: $unit;
  }
}
</style>
