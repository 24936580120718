<template>
  <a
    :href="data.acf.url"
    target="_blank"
    class="thumb"
  >
    <Flex
      dir="column"
      class="flex-height"
    >
      <Figure
        v-if="data.gds_featured_image"
        :data="data.gds_featured_image"
        class="media"
      />
      <Spacer
        class="abstract"
        all="m"
      >
        <Flex
          dir="column"
          class="flex-height"
          align="h-between"
        >
          <Label :data="{ value: data.date_format, size: 'xs', tag: 'div' }" />
          <Spacer top="s">
            <Title :data="{ value: data.title.rendered, size: 'l' }" />
          </Spacer>
        </Flex>
      </Spacer>
    </Flex>
  </a>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  width: 100%;
  position: relative;
  display: block;
  background: $white;
}

.flex-height {
  height: 100%;
}

.media {
  width: 100%;
  aspect-ratio: 4/3;

  ::v-deep {
    picture {
      height: 100%;
      padding-bottom: 0 !important;
    }
  }
}

.abstract {
  flex: 1 0 0;

  .label {
    color: currentColor;
  }
}
</style>
