import Vue from 'vue';
import Aion from '@adoratorio/aion';

const aion = new Aion();

aion.install = () => {
  aion.start({
    autostop: false,
  });
  Vue.prototype.$aion = aion;
};

Vue.use(aion);
